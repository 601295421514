// {% comment %} JQuery {% endcomment %}
window.kundeportal_init_orgtype = function (org) {
  const er_vertsorg = $('#id_er_vertsorg');
  const subtypes = $('#id_er_kommune, #id_er_fylke, #id_er_uh');

  let prevAjax = null;
  function update_org_tilgang() {
    const data = {};
    if ($('#id_er_vertsorg').prop('checked')) {
      data.vertsorg = '';
    }
    if ($('#id_er_kommune').prop('checked')) {
      data.kommune = '';
    }
    if ($('#id_er_fylke').prop('checked')) {
      data.fylke = '';
    }
    if ($('#id_er_uh').prop('checked')) {
      data.uh = '';
    }

    if (prevAjax != null) {
      prevAjax.abort();
    }

    prevAjax = $.ajax({
      url: `/admin/ajax/org/${org}/tilgang/`,
      data,
      dataType: 'json',
      success(successData) {
        function updateList(element, list) {
          if (element.length === 0) {
            return;
          }
          if (list.length === 0) {
            element.hide();
            return;
          }

          const ul = element.find('ul');
          ul.empty();
          for (let i = 0; i < list.length; i += 1) {
            ul.append($('<li>').text(list[i]));
          }
          element.show();
        }
        updateList($('#add-services'), successData.add);
        updateList($('#remove-services'), successData.remove);
        updateList($('#inuse-services'), successData.inuse);
        prevAjax = null;
      },
    });
  }

  function set_realm_and_support_fields_enable(enable) {
    let fields = $('#id_realm, #id_support_telefonnummer, #id_support_epost');
    fields = fields.add($('.languagewidget_row[id^="languagewidget_support_url_"]').find('input, a'));
    fields = fields.add($('.languagewidget_row[id^="languagewidget_passordbytte_url_"]').find('input, a'));
    if (enable) {
      fields.removeAttr('readonly');
      $('#languagewidget_support_url_langadd').show();
      $('#languagewidget_passordbytte_url_langadd').show();
    } else {
      fields.attr('readonly', 'readonly');
      $('#languagewidget_support_url_langadd').hide();
      $('#languagewidget_passordbytte_url_langadd').hide();
    }
  }

  function set_deactivate_services_warning(enable) {
    if (enable) {
      $('#deactivate-services-warning').show();
    } else {
      $('#deactivate-services-warning').hide();
    }
  }

  function update_subtypes() {
    if (er_vertsorg.attr('disabled')) {
      return;
    }
    if (!er_vertsorg.prop('checked')) {
      subtypes.attr('disabled', 'disabled').removeAttr('checked');
      set_realm_and_support_fields_enable(false);
    } else {
      subtypes.removeAttr('disabled');
      set_realm_and_support_fields_enable(true);
    }
    update_org_tilgang();
  }

  function update_deactivation_warning() {
    const showWarning = !er_vertsorg.prop('checked');
    set_deactivate_services_warning(showWarning);
  }

  er_vertsorg.on('change', update_subtypes);
  er_vertsorg.on('change', update_deactivation_warning);
  subtypes.on('change', update_org_tilgang);

  update_subtypes();
};

window.kundeportal_init_tilgang_form = function () {
  function setState(checkbox, newState) {
    if (checkbox.disabled) {
      return;
    }

    if (checkbox.checked === newState) {
      return;
    }

    checkbox.checked = newState;
  }

  function updatePartial(element) {
    const all = $(element).find(':checkbox');
    const checked = $(element).find(':checkbox:checked');
    let partial = false;
    if (checked.length !== 0 && checked.length !== all.length) {
      partial = true;
    }
    const dpartial = $(element).data('partial');
    if ((!dpartial && !partial) || dpartial === partial) {
      return;
    }
    $(element).data('partial', partial);
    const textElement = $(element).find('.group-text').first();
    if (partial) {
      textElement.wrap('<em></em>');
    } else {
      textElement.unwrap();
    }
    $($(element).data('parent')).each(function () {
      updatePartial(this);
    });
  }

  const group_cbs = $('.tilgang_list_root .group_cb');
  group_cbs.on('change', function () {
    const newState = this.checked;
    $(this)
      .parents('ul')
      .first()
      .find('input[type="checkbox"]')
      .each((i, childCB) => {
        setState(childCB, newState);
      });
    $($(this).data('parent')).each(function () {
      updatePartial(this);
    });
  });

  const leaf_cbs = $('.tilgang_list_root .leaf_cb');
  leaf_cbs.on('change', function () {
    const newState = this.checked;
    const val = this.value;
    $(`.tilgang_list_root input[value="${val}"]`).each((i, other) => {
      setState(other, newState);
    });
    $($(this).data('parent')).each(function () {
      updatePartial(this);
    });
  });
  $('.tilgang_list_root .group').each(function () {
    updatePartial(this);
  });

  $('.search').on('keyup', function () {
    const q = this.value.toLowerCase().String.trim();
    $(this)
      .next()
      .find('li.group li.group ul li')
      .each(function () {
        if ($(this).children('label').text().toLowerCase().indexOf(q) >= 0) {
          $(this).show();
          if (q.length >= 2) {
            $(this).parents('div').first().collapse('show');
          }
        } else {
          $(this).hide();
        }
        if (q.length < 2) {
          $(this).parents('div').first().collapse('hide');
        }
      });
  });
};

window.kundeportal_init_languages = function () {
  function hideRow(row) {
    row.hide();
    row.find('input, textarea').val('');

    const lang = row.attr('id');
    const selid = `${lang.substr(0, lang.length - 3)}add`;
    $(`#${selid}`).show();
  }

  $('.languagewidget_row')
    .find('input, textarea')
    .each(function () {
      if (this.value === '') {
        const row = $(this).closest('.languagewidget_row');
        if (!row.hasClass('showdefault')) {
          hideRow(row);
        }
      }
    });

  function updateTextareaHeight() {
    const e = $(this);
    const padding = e.innerHeight() - e.height();
    $(this).height(50);
    $(this).height(e[0].scrollHeight - padding);
  }

  $('.languagewidget_row')
    .find('textarea')
    .on('change', updateTextareaHeight)
    .on('input', updateTextareaHeight)
    .each(updateTextareaHeight);

  $('.lang_delete').on('click', function () {
    const row = $(this).closest('.languagewidget_row');
    hideRow(row);
  });

  $('.lang_add a').on('click', function () {
    const add = $(this).closest('li');
    const add_id = add.attr('id');
    const row_id = `${add_id.substr(0, add_id.length - 3)}row`;
    const row = $(`#${row_id}`);
    row.show();
    row.find('textarea').each(updateTextareaHeight);
    add.hide();
  });
};

function kundeportal_init_contactsearch(root, add_results_callback) {
  if (root.length === 0) {
    /* No contact search element - nothing to do here. */
    return;
  }

  let remaining_results = null;

  function show_remaining() {
    root.find('.toomany').hide();
    add_results_callback(remaining_results);
    remaining_results = null;
    return false;
  }

  function update_results(data) {
    let results = data;
    root.find('.loading').hide();
    root.find('.error').hide();
    if (results.length === 0) {
      root.find('.noresults').show();
      root.find('.results').hide();
      root.find('.toomany').hide();
      return;
    }

    if (results.length > 20) {
      remaining_results = results.slice(20);
      results = results.slice(0, 20);

      const toomany = root.find('.toomany');
      const link = toomany.find('a');
      link.on('click', show_remaining);
      link.text(`${remaining_results.length}${$(link).text()}`);
      toomany.show();
    } else {
      root.find('.toomany').hide();
    }

    root.find('.noresults').hide();
    root.find('.results').show();

    root.find('tr.result').remove();
    add_results_callback(results);
  }

  function on_error(jqXHR, textStatus) {
    root.find('.loading').hide();
    if (textStatus === 'abort') {
      return;
    }
    root.find('.results').hide();
    root.find('.toomany').hide();

    if (textStatus) {
      root.find('.errortext').text(textStatus);
    } else {
      root.find('.errortext').text('Ukjent feil.');
    }
    root.find('.error').show();
  }

  let active_search = '';
  let active_ajax = null;
  function update_search() {
    const new_value = root.find('input').val();
    if (new_value === active_search) {
      return;
    }
    active_search = new_value;

    if (active_ajax != null) {
      active_ajax.abort();
    }

    if (active_search === '') {
      root.find('.noresults').show();
      root.find('.toomany').hide();
      root.find('.results').hide();
      root.find('.loading').hide();
      return;
    }

    active_ajax = $.ajax({
      url: '/admin/ajax/find_contact/',
      data: { query: active_search },
      dataType: 'json',
      error: on_error,
      success: update_results,
    });
    root.find('.loading').show();
  }

  root.find('input').on('change', update_search);
  root.find('input').on('keyup', update_search);
  update_search();
}

window.kundeportal_init_rolle_extcontact = function () {
  function add_contact() {
    const clist = $('#rolle-contacts');

    const old = clist.find(`input[value="${this.contact_id}"]`);
    if (old.length > 0) {
      old.prop('checked', true);
      return;
    }

    const cb = $(`<input type="checkbox" name="kontakt" value="${this.contact_id}" checked >`);
    const link = $(`<a href="${this.contact_id}/">text</a>`);
    link.text(this.contact_name);
    const org = $('<span></span');
    org.text(`(${this.contact_org})`);

    const label = $('<label>');
    label.append(cb);
    label.append(' ');
    label.append(link);
    label.append(' ');
    label.append(org);

    const item = $('<div class="checkbox">');
    item.append(label);
    clist.append(item);
  }

  function add_results(data) {
    const toomany = $('#extcontact .toomany');
    for (let i = 0; i < data.length; i += 1) {
      const c = data[i];
      const row = $('<tr class="result">');
      row.append($('<td>').text(c.name || ''));
      row.append($('<td>').text(c.mail || ''));
      row.append($('<td>').text(c.org || ''));
      row.prop('contact_id', c.id);
      row.prop('contact_name', c.name);
      row.prop('contact_org', c.org || '');
      row.on('click', add_contact);
      row.insertBefore(toomany);
    }
  }

  kundeportal_init_contactsearch($('#extcontact'), add_results);
};

window.kundeportal_init_contactsearch_page = function () {
  function add_contacts(data) {
    const toomany = $('.contact-search .toomany');
    for (let i = 0; i < data.length; i += 1) {
      const c = data[i];
      const row = $('<tr class="result">');
      const link = $(`<a href="${c.id}/">`);
      link.text(c.name || '');
      row.append($('<td>').append(link));
      row.append($('<td>').text(c.mail || ''));
      row.append($('<td>').text(c.org || ''));
      row.insertBefore(toomany);
    }
  }

  kundeportal_init_contactsearch($('.contact-search'), add_contacts);

  function check_hash() {
    let new_value = '';
    if (window.location.hash) {
      new_value = window.location.hash.substring(1);
    }

    const input = $('.contact-search input');
    const old_value = input.val();
    if (old_value !== new_value) {
      input.val(new_value);
      input.trigger('change');
    }
  }
  $(window).on('hashchange', check_hash);
  check_hash();

  $('.contact-search input').on('blur', function () {
    let new_value = '';
    if (this.value !== '') {
      new_value = `#${this.value}`;
    }
    if (new_value !== window.location.hash) {
      window.location.hash = new_value;
    }
  });

  $('.contact-search input').trigger('focus');
};

window.kundeportal_init_target = function () {
  const all = $('#id_publiseres_alle');
  const single = $('input[type="checkbox"][id^=id_publiseres][id!=id_publiseres_alle]');

  function checkAll() {
    let flag = true;
    single.each(function () {
      if (this.checked === false) {
        flag = false;
      }
      all.prop('checked', flag);
    });
  }

  checkAll();

  all.on('change', function () {
    if (this.checked) {
      single.prop('checked', true);
    } else {
      single.prop('checked', false);
    }
  });

  single.on('change', function () {
    if (!this.checked) {
      all.prop('checked', false);
    } else {
      checkAll();
    }
  });
};

window.kundeportal_init_list_tjeneste_twofactor = function () {
  $('#tjenestetable').tablesorter({
    sortList: [[0, 0]],
  });
  $('#tjenestetable select').on('change', function () {
    const form = $(this).closest('form');
    const row = $(this).closest('tr')[0];
    const service_name = row.cells[0].innerText;
    const changed_to = $(this)[0].selectedOptions[0].text;
    window.trackEvent('authentication', 'change-strong-authentication', `${service_name} changed to ${changed_to}`);
    form.trigger('submit');
  });
};

function kundeportal_show_trackid_detail(data) {
  const modal = $('#error-detail-modal');

  modal.find('tr.detail-row').each(function () {
    const self = $(this);
    const src_attr = self.data('src');
    const td = self.find('td');
    if (src_attr in data) {
      td.text(data[src_attr]);
      self.show();
    } else {
      td.text('');
      self.hide();
    }
  });

  modal.modal();
}

window.kundeportal_init_lookup_trackid = function () {
  $('button.detail-button').on('click', function () {
    const row = $(this).closest('tr');
    kundeportal_show_trackid_detail(row.data());
  });
};

window.kundeportal_init_change_logo = function () {
  $('.btn-file').on('change', function () {
    $(this).closest('form').submit();
  });
};

window.kundeportal_init_merger_org = function () {
  $('#tjenestetable').tablesorter({
    sortList: [[0, 0]],
  });
  $('#missing_activation').tablesorter({
    sortList: [[0, 0]],
  });
  $('button.selectall').on('click', function () {
    const btn = $(this);
    let value = true;
    if (btn.hasClass('active')) {
      value = false;
    }
    $(this.form).find('input[type=checkbox]').prop('checked', value);
  });
  $('.hastooltip').tooltip();
  $('#tjenestetable').tablesorter({
    widgets: ['stickyHeaders'],
    widgetOptions: {
      // css class name applied to the sticky header
      stickyHeaders: 'tablesorter-stickyHeader',
    },
  });
};

window.kundeportal_sort_tables = function () {
  $('table.sorted').tablesorter({
    sortList: [[0, 0]],
  });
};

window.kundeportal_init_entitlements = function () {
  $('.feide-entitlement-add').each(function () {
    const prefix_list = $(this).prev('ul');
    const input_field = $(this).find('input');
    const add_button = $(this).find('button');
    const name = prefix_list.data('inputName');
    add_button.on('click', () => {
      const new_prefix = input_field.toString().trim();
      if (!new_prefix) {
        return;
      }
      const new_element = $('<li><label><input type="checkbox" checked> <span></span></label></li>');
      new_element.find('input').attr('name', name).val(new_prefix);
      new_element.find('span').text(new_prefix);
      prefix_list.append(new_element);
      input_field.val('');
    });
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/sending-data#handle_timeouts
function createFunctionWithTimeout(callback, opt_timeout) {
  let called = false;
  function fn() {
    if (!called) {
      called = true;
      callback();
    }
  }
  setTimeout(fn, opt_timeout || 1000);
  return fn;
}

window.trackEvent = function (category, action, label, value, callback) {
  if (!window._paq) {
    if (callback) callback();
    return;
  }

  // undefined is "customData" object.
  window._paq.push([
    'trackEvent',
    category,
    action,
    label,
    value,
    undefined,
    callback ? createFunctionWithTimeout(callback) : undefined,
  ]);
};

window.trackLink = function (url, category, action, label, value) {
  if (!window._paq) {
    window.location.href = url;
    return;
  }

  window.trackEvent(category, action, label, value, () => {
    window.location.href = url;
  });
};

// User guide for matomo JavaScript tracking client: https://developer.matomo.org/4.x/guides/tracking-javascript-guide
// JavaScript Tracker reference: https://developer.matomo.org/4.x/guides/tracking-javascript
// trackPage should run on all pages that use Matomo tracking, as it initializes the Matomo instance.
window.trackPage = function () {
  const regex = /\/\d+/; // Matches '/123' (more than once if necessary)
  const path = window.location.pathname.replace(regex, '/id'); // So we don't track different orgs as separate pages.

  const _paq = window._paq || [];
  window._paq = _paq; // https://stackoverflow.com/a/62773149

  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(['setCustomUrl', path]);
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);

  // IIFE that makes sure that tracking data gets sent to the correct place by initializing the Matomo instance.
  (function () {
    const matomoUrl = 'https://matomo.sikt.no/';
    const d = document;
    const g = d.createElement('script');
    const s = d.getElementsByTagName('script')[0];
    _paq.push(['setTrackerUrl', `${matomoUrl}piwik.php`]);
    _paq.push(['setSiteId', '46']);
    g.async = true;
    g.src = `${matomoUrl}piwik.js`;
    s.parentNode.insertBefore(g, s);
  })();
};

window.trackSearch = function (category, action) {
  if (!window._paq) return;
  window._paq.push(['trackSiteSearch', action, category, false]);
};
